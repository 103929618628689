.code-content {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: .5s ease-in height;
    position: relative;
    transform: translateY(-200vh);

    &:after {
        height: 250px;
        width: 100%;
        content: " ";
        position: absolute;
        bottom: -125px;
        left: 0;
        display: block;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a0a0a+0,0a0a0a+100&0+0,1+46,1+54,0+100 */
        background: -moz-linear-gradient(top,  rgba(10,10,10,0) 0%, rgba(10,10,10,1) 46%, rgba(10,10,10,1) 54%, rgba(10,10,10,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(10,10,10,0) 0%,rgba(10,10,10,1) 46%,rgba(10,10,10,1) 54%,rgba(10,10,10,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(10,10,10,0) 0%,rgba(10,10,10,1) 46%,rgba(10,10,10,1) 54%,rgba(10,10,10,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    @include media-tablet-down {
        width: 150vw;
        margin-left: -25vw;
    }

    &.active {
        transform: translateY(0);
        display: block;
        height: 80vh;
        margin-bottom: -12vh;
        @include media-tablet-down {
            height: 70vh;
            margin-bottom: -10vw;
        }
    }
}

