.carousel-object {
    overflow: hidden;


  .carousel {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .carousel-sections-scroll {
    flex: 1;
    overflow: hidden;
  }
  .carousel-sections {
    height: 300px;
    display: flex;
    transition: 500ms transform ease-in;
    width: 100%;
  }
  
  .carousel-sections > div {
    min-width: 100%;
    height: 100%;
    border-radius: 24px;
  }
  


  
  .prev-arrow:after,
  .prev-arrow:before,
  .next-arrow:after,
  .next-arrow:before {
    content: "";
    height: 10px;
    width: 2px;
    border-radius: 10px;
    position: absolute;
    left: 10px;
    background-color: transparent;
  }
  .next-arrow:after,
  .next-arrow:before {
    left: 12px;
  }
  
  .prev-arrow:after {
    top: 4px;
    transform: rotate(45deg);
  }
  .prev-arrow:before {
    top: 10px;
    transform: rotate(-45deg);
  }
  
  .next-arrow:after {
    top: 4px;
    transform: rotate(-45deg);
  }
  .next-arrow:before {
    top: 10px;
    transform: rotate(45deg);
  }
  
  .carousel-dots {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carousel-dot {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 8px;
    background-color: #afafaf;
    margin: 0px 4px;
    transition: 100ms background-color ease-in;
  }
  .carousel-dot.active {
    background-color: #5f5f5f;
  }



  
  .prev-arrow,
  .next-arrow {
    z-index: 10;
    position: absolute;
    height: 24px;
    width: 24px;
    margin: 0px 12px;
    background-color: transparent;
    cursor: pointer;
    font-size: 0;
    user-select: none;
    top: 50%;
    transform: translateY(-50%);
    width: 54px;
    height: 54px;
    background-color: transparent;
    background-size: cover;
    padding: 0;
    font-size: 0;


    @include media-tablet-down {
        width: 35px;
        height: 35px;
    }

    &:after{
      transition: 250ms all ease-in;
      width: 100%;
      height: 100%;
      display: block;
      border: 0;
      transform: none;
      border-radius: 0;
      top: 0;
      left: 0;
      right: 0;
    }

    &:before{display: none}

    
    &:hover:after {
      opacity: 1;
    }
  }


  .prev-arrow {
    left: 0;
    @include backgroundImage('images/main-carousel-arrow-left.png');
    &:after {
      @include backgroundImage('images/main-carousel-arrow-left.png');
    }
  }

  .next-arrow {
    right: 0;
    @include backgroundImage('images/main-carousel-arrow-right.png');
    &:after {
      @include backgroundImage('images/main-carousel-arrow-right.png');
    }
  }

  .prev-arrow:after,
  .next-arrow:after {
    opacity: 0;
    background-size: cover;
    filter: brightness(110%) saturate(166%);
    -webkit-filter: brightness(110%) saturate(166%);
    -moz-filter: brightness(110%) saturate(166%);

  }

}
  