body {
    background-color: $black;
    color: $white;
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
}

.parallax {
    transition: .2s ease all;
    @include media-phone-only {
        transition: .25s ease all;
    }
}

.anim-cutter {
    display: block;
    overflow: hidden;
}

.wrapper {
    width: 1074px;
    max-width: calc(100% - 60px);
    margin: 0px auto;
    position: relative;
    z-index: 10;
    display: block;
}

nav {
    display: block;
    margin: 0;
    padding: 0;
    color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $black;
    z-index: 170;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-grow: 1;
        padding: 10px;
    }

    .button {
        border: 0;
        font-family: 'Noir';
        text-transform: uppercase;
        height: 60px;
        text-align: center;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        text-decoration: none;
        line-height: 110%;
        padding: 0 15px;
        margin: 0 10px;
        position: relative;
        transition: .4s ease-in all;
        border-radius: 5px;
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        background: rgba(200, 200, 200, 0.0);
        flex-direction: column;
        cursor: pointer;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            bottom: -5px;
            left: center;
            width: 0%;
            height: 2px;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            opacity: 0;
            transition: .6s ease-in all;
        }

        &:after {
            background-color: $white;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -10px;
            content: " ";
            display: block;
        }

        &:last-child:after {
            display: none;
        }


        &:hover {
            color: $highlight;
            border-radius: 10px;

            &::before {
                opacity: 1;
                width: 100%;
            }
        }

        @include media-tablet-down {
            font-size: 13px;
            padding: 0 5px;
            margin: 0 5px;
            height: 30px;

            &:after {
                right: -5px;
            }
        }
    }

    .press-kit {
        text-decoration: none;
        color: $white;
        position: fixed;
        bottom: -10px;
        left: -60px;
        background-color: rgba($darkyellow, 0.5);
        width: 100px;
        padding: 10px 35px 39px;
        text-transform: uppercase;
        text-align: center;
        transform: rotate(45deg);
        transform-origin: center center;
        opacity: 0.8;
        transition: .3s ease all;
        cursor: pointer;

        &:after {
            width: 20px;
            height: 20px;
            @include backgroundImage('images/arrow.png',0 0,'no-repeat');
            background-size: contain;
            display: inline-block;
            position: fixed;
            bottom: 10px;
            left: 71px;
            content: " ";
            z-index: 1000;
        }

        &:hover {
            opacity: 1;
            transform: rotate(45deg) scale(1.1);
            background-color: rgba($darkyellow, 1.0);
        }
    }
}

.main-content {
    margin-top: 82px;
    background-position-y: top;
    background-position-x: center;
    position: relative;
    @include backgroundImage('images/texture-min-min.webp',0 0,'repeat');
    background-size: 50vw;
    animation: background-slide 100s linear infinite;
    @include media-tablet-down {
        margin-top: 52px;
    }
    .logo-container {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
            width: auto;
            height: calc(100vh - 90px);
            max-height: 1000px;
            margin: 0px auto;
            animation: scale-anim-11 40s linear infinite;
            @include media-tablet-down {
                width: 100%;
                height: auto;
            }
        }
    }

    .description {
        font-size: 25px;
        line-height: 130%;
        @include media-tablet-down {
            font-size: 15px;
        }
    }

    .wrapper {
        z-index: 10;
    }

    .description {
        text-align: center;
        h1 {
            text-transform: uppercase;
            display: inline-block;
            text-align: center;
            position: relative;
            font-family: 'Bazar';
            font-size: 40px;
            margin-bottom: 50px;
            margin-top: 30px;
            @include media-tablet-down {
                font-size: 22px;
                margin-top: 30px;
            }

            &:after {
                content: " ";
                height: 3px;
                width: calc(100% + 60px);
                left: -30px;
                bottom: -10px;
                background: $white;
                display: block;
                position: absolute;
                @include media-phone-only {
                    width: calc(100% + 40px);
                    left: -20px;
                }
            }
        }


        ul, ol, p {
            margin-bottom: 30px;
        }

        p, li {
            text-align: left;
        }
    }

    .youtube-widget-container {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-desktop-up {
            margin-top: 50px;
            margin-bottom: 20px;
        }

        iframe {
            overflow: hidden;
            border-radius: 30px;
            width: 60vw;
            height: 33.75vw;
            margin-top: 20px;
            margin-bottom: 30px;

            @include media-phone-only {
                margin-left: -30px;
                margin-right: -30px;
                width: calc(100% + 60px);
                height: 56.25vw;
                border-radius: 0;
                margin-bottom: 10px;
            }
            @include media-desktop-up {
                width: 1360px;
                height: 600px;
            }
        }
    }

    .steam-widget-container {
        padding: 15px 0;
        @include media-desktop-up {
            padding-top: 70px;
        }


        iframe {
            width: 100%;
            @include media-phone-only {
                margin-left: -30px;
                margin-right: -30px;
                width: calc(100% + 60px);

            }
        }
    }

    .socials {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 0px;
        padding-bottom: 30px;
        margin-top: -30px;

        @include media-tablet-down() {
            padding-bottom: 50px;
            padding-top: 30px;
        }

        a {
            display: inline-block;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin: 20px;
            background-size: contain;
            background-position: center center;
            transition: .3s ease-in all;
            box-shadow: 0 0 10px 7px rgba($highlight, 0.0);
            

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 0 20px 4px rgba($highlight, 0.4);
            }

            @include media-tablet-down {
                width: 40px;
                height: 40px;
                margin: 10px;
            }

            &.steam {
                background-image: url($assets + 'images/social-steam.webp');
            }

            &.instagram {
                background-image: url($assets + 'images/social-instagram.webp');
            }

            &.twitter {
                background-image: url($assets + 'images/social-twitter.webp');
            }

            &.facebook {
                background-image: url($assets + 'images/social-facebook.webp');
            }

            &.discord {
                background-image: url($assets + 'images/social-discord.webp');
            }

            &.tiktok {
                background-image: url($assets + 'images/social-tiktok.webp');
            }
        }
    }

    .background-extra-layer {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
        &.top-light{
            position: fixed;
            top: -100px;
            left: 0;
            right: 0;
            box-shadow: 0 0 100px 100px rgba($white, 0.15);
            background: rgba($white, 0.2);
            height: 100px;
            border-radius: 40%;

        }


        &.bottom-light{
            box-shadow: 0 0 50vh 50vh rgba($white, 0.1);
            border-radius: 50%;
            height: 50vh;
            top: initial;
            bottom: -50vh;

        }
        &.yellow-splash-side{
            background-image: url($assets + 'images/yellow_slpash_side.png');
            background-position: right 30vh;
            background-size: 30vh;
            transition: 2.3s ease transform;

            @include media-desktop-up {
                background-size: 20vw;
                
            }
        }
        &.yellow-splash-bottom{
            background-image: url($assets + 'images/yellow_slpash_bottom.png');
            background-position: center bottom;
            
            background-size: 50vw;
            @include media-tablet-down {
                background-size: 100vw;
            }
            @include media-desktop-up {
                background-size: 50vw;
            }
        } 
        &.bg-layer{
            background-image: url($assets + 'images/bg-layer.webp');
            background-position: center 47vh;
            background-size: 100vw;
            background-repeat: no-repeat;
            @include media-phone-only {
                background-position: center 31vh;
            }

            &.active {
                @include media-phone-only {
                    background-position: center 47vh;
                }
            }
        }
    }

}

@keyframes scale-anim-11 {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1.0);}
}

@keyframes scale-anim-12 {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1.0);}
}

@keyframes background-slide {
    0% {background-position-x: 0vw;}
    100% {background-position-x: 100vw;}
}


